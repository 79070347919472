import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { 
  Box,
  Checkbox, 
  FormControlLabel, 
} from '@mui/material';
import { PostApi } from '../../api/post.api';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { PostCard } from '../../components/PostCard';
import { hasFeatures } from '../../store/auth';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { Loader } from '../../components/Loader';
import { Container } from '../../components/Container';
import { usePagination } from '../../utilities/hooks';
import { PostModel } from '../../models/post.model';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/post.model').PostModel|null} post
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'Post ID',
    value: 'post_id',
  },
  {
    label: 'Author ID',
    value: 'author_id',
  },
  {
    label: 'Post',
    value: 'title',
  }
];

export const Posts = () => {
  const dispatch = useDispatch();
  const canDeletePost = useSelector(hasFeatures(['delete-post']));
  const confirmDialogProps = useSelector(getConfirmDialog);
  const confirmDialogDataPostId = confirmDialogProps.data?.post_id;
  /** @type {History} */
  const history = useHistory();
  /** @type {[import('../../models/post.model').PostModel[], (value: import('../../models/post.model').PostModel) => void]} */
  const [posts, setPosts] = useState([]);
  const [loader, setLoader] = useState(false);
  /** @type {[Recrd<string, string>, (value: Recrd<string, string>) => void]} */
  /** @type {[boolean, (value: boolean) => void]} */
  const [firstFrame, setFirstFrame] = useState(false);
  /** @type {[boolean, (value: boolean) => void]} */
  const [reboundImage, setReboundImage] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'posts',
    item: searchItems[0],
    text: '',
  });

  const {
    page,
    limit,
    getPageState,
    setPageState,
    next,
    previous,
  } = usePagination({
    key: 'posts',
  });

  const loadPosts = useCallback(async () => {
    try {
      setLoader(true);
      const { value: searchItemValue } = searchItem;
      if (searchItemValue === 'title' && searchTextAsDebounced.length > 0) {
        const { data } = await PostApi.getPostsOfRecrd({
          title: searchTextAsDebounced,
        });
        const newPosts = data.map(v => {
          const item = new PostModel({
            author_id: v.author_id,
            first_frame: v.first_frame,
            thumb_url: v.thumb_url,
            narrative: v.narrative,
            post_id: v.post_id,
            post_type: v.post_type,
            post_status: v.post_status,
            hashtags: v.hashtags,
            title: v.title,
            width: v.width,
            height: v.height,
          })
          return item;
        })
        setPosts(newPosts);
      } else {
        const data = await PostApi.getPosts({
          postId: getSearchTextByItemValue('post_id', true),
          authorId: getSearchTextByItemValue('author_id', true),
          title: getSearchTextByItemValue('title', true),
          includes: ['user', 'user.userSettings'],
          limit,
          pageState: getPageState(searchTextAsDebounced?.length > 0),
        });
        setPageState(data.pageState);
        setPosts(data.posts);
      }
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The posts can't be loaded",
      }));
    } finally {
      setLoader(false);
    }
  }, [
    limit,
    searchTextAsDebounced,
    searchItem,
    dispatch, 
    getPageState, 
    setPageState, 
    getSearchTextByItemValue,
  ]);

  const dialogSubmit = useCallback(async () => {
    try {
      dispatch(setConfirmDialog({
        open: false,
        result: null,
      }))
      setLoader(true);
      await PostApi.deletePost({
        postId: confirmDialogDataPostId,
      });
      loadPosts();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "Post has just been deleted"
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Post hasn't been deleted"
      }));
    } finally {
      dispatch(setConfirmDialog({
        open: false,
        result: null,
        data: null,
      }))
      setLoader(false);
    }
  }, [confirmDialogDataPostId, dispatch, loadPosts]);

  useEffect(() => {
    if (confirmDialogProps.result === true) {
      dialogSubmit();
    }
  }, [confirmDialogProps.result, dialogSubmit]);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  const postClick = useCallback((/** @type {import('../../models/post.model').PostModel} */ post) => () => {
    history.push(`/posts/${post.post_id}`);
  }, [history]);

  const postDelete = useCallback((/** @type {import('../../models/post.model').PostModel} */ post) => () => {
    if (!canDeletePost) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "You don't have access to delete a post",
      }));
      return;
    }
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader(),
      content: getConfirmDialogContent('post'),
      result: null,
      data: post,
    }));
  }, [canDeletePost, dispatch]);

  const paginationNextClick = useCallback(() => {
    next();
    loadPosts();
  }, [next, loadPosts]);

  const paginationPreviousClick = useCallback(() => {
    previous();
    loadPosts();
  }, [previous, loadPosts]);

  const firstGrameChange = useCallback((_, checked) => {
    setFirstFrame(checked);
  }, []);

  const reboundImageChange = useCallback((_, checked) => {
    setReboundImage(checked);
  }, []);

  return (
    <Layout title="Posts">
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <Box display="flex" padding="0 0 10p 0">
          <Box>
            <FormControlLabel 
              control={<Checkbox checked={firstFrame} onChange={firstGrameChange} />} 
              label="First Frame" 
            />
          </Box>
          <Box>
            <FormControlLabel 
              control={<Checkbox checked={reboundImage} onChange={reboundImageChange} />} 
              label="Rebound Image" 
            />
          </Box>
        </Box>
        <Container>
          {posts.map(post => (
            <PostCard
              key={post.post_id}
              post={post}
              firstFrame={firstFrame}
              reboundImage={reboundImage}
              isDeleteButton={canDeletePost}
              onClick={postClick(post)}
              onDelete={postDelete(post)}
            />
          ))}
        </Container>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        <Loader loading={loader} />
      </Container>
    </Layout>
  );
};  