import React, { useCallback, useRef, useState } from 'react';
import { FormTypes, TextColors } from '../../../constants';
import { useFormikContext } from 'formik';
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { Container } from '../../../components/Container';
import styled from '@emotion/styled';
import { DialogOfRoles } from '../../../components/DialogOfRoles';
import { Accesses } from '../../../constants/access';

const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    max-width: 100vw;
  }
`;

export const Form = ({
  access = Accesses.Internal,
  formType,
  show,
  onSubmit,
  onCancel,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    setValues,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const emailRef = useRef();

  const emailHintClick = useCallback(() => {
    emailRef.current.focus();
  }, []);
  const [dialogOfRolesOpen, setDialogOfRolesOpen] = useState(false);

  const attributesClick = useCallback(() => {
    setDialogOfRolesOpen(true)
  }, []);

  const dialogOfRolesClose = useCallback(() => {
    setDialogOfRolesOpen(false)
  }, []);

  const dialogOfRolesSubmit = useCallback((newRoles) => {
    const newValues = {
      ...values,
      attributes: [...newRoles],
    }
    setValues(newValues);
  }, [values, setValues]);

  return (
    <Dialog
      open={show}
      onClose={onCancel}
    >
      <DialogTitle>
        <Text>
          {formType === FormTypes.Create && <>Create</>}
          {formType === FormTypes.Update && <>Edit</>}
        </Text>
      </DialogTitle>
      <DialogContent>
        <Box flexDirection="column" width="320px" padding="10px 0" gap="10px">
          <Box position="relative">
            <TextField
              name="email"
              label="Email"
              fullWidth
              disabled={formType === FormTypes.Update}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              inputRef={emailRef}
              InputLabelProps={{ shrink: true }}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
            />
            {access === Accesses.Internal && (
              <Box 
                position="absolute" 
                top="15px"
                left={14 + values.email.length * 8.8 + `px`} 
                onClick={emailHintClick}
              >
                <Text color="rgb(158, 158, 158)" size="16px">
                  @recrd.com
                </Text>
              </Box>
            )}
          </Box>
          <Container>
            <Button
              fullWidth
              variant="outlined"
              color={errors.attributes ? 'error' : 'success'}
              onClick={attributesClick}
            >
              Attributes
            </Button>
            {errors.attributes && (
              <Text color={TextColors.Error} size="12px">
                {errors.attributes}
              </Text>
            )}
            <DialogOfRoles
              access={access}
              title="Attributes"
              open={dialogOfRolesOpen}
              value={values.attributes}
              onSubmit={dialogOfRolesSubmit}
              onClose={dialogOfRolesClose}
            />
          </Container>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box gap="10px">
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="info"
              disabled={!isValid}
              onClick={onSubmit}
            >
              {formType === FormTypes.Create && <>Create</>}
              {formType === FormTypes.Update && <>Update</>}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}