
export const ActionStatuses = {
  Idle: 'idle',
  Pending: 'pending',
  Fulfilled: 'fulfilled',
  Rejected: 'rejected',
};

export const TextColors = {
  Primary: 'rgb(255, 255, 255)',
  Error: 'rgb(211, 47, 47)',
  Email: 'rgb(142, 172, 205)',
  Phone: 'rgb(230, 217, 162)',
  Name: 'rgb(50, 205, 50)',
  Name2: 'rgb(118, 149, 255)',
  Title: 'rgb(0,225,225)',
  Message: 'rgb(198, 143, 230)',
  Text: 'rgb(255, 255, 255)',
  Subtext: 'rgb(158, 158, 158)',
  Url: 'rgb(30,144,255)',
  Status: 'rgb(30,144,255)',
  Status2: 'rgb(0, 100, 255)',
  Info: 'rgb(105, 117, 101)',
  List: 'rgb(252, 222, 112)',
  Choice: 'rgb(210, 224, 251)',
  DateTime: 'rgb(137, 103, 179)',
  ID: 'rgb(255,140,0)',
  ID2: 'rgb(232, 92, 13)',
};

export const TextFontSizes = {
  Smaller: '12px',
  Small: '16px',
  Normal: '18px',
  Large: '22px',
  Larger: '28px',
}

export const FormTypes = {
  Create: 'create',
  Update: 'update',
};

export const SnackbarTypes = {
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
};

export const SaleStatuses = {
  Nothing: 0,
  ForSale: 1,
  Suspended: 2,
  Blocked: 3,
  Removed: 4,
  Sold: 5,
  Pending: 6,
}

export const PaginationAction = {
  Unknown: 'unknown',
  Previous: 'previous',
  Next: 'next',
}

export const confirmDialogHeaderText = 'Are you sure you want to delete?';