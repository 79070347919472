import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { SnackbarTypes } from '../../constants';
import { Container } from '../../components/Container';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader } from '../../components/Loader';
import { PartnerApi } from '../../api/partner.api';
import { Pagination } from '../../components/Pagination';
import { UserCard } from '../../components/UserCard';
import { UserModel } from '../../models/user.model';
import { UserSettingsModel } from '../../models/user-settings.model';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

/** @typedef {import('../../models/user.model').UserModel} UserModel */


export const Partner = () => {
  const dispatch = useDispatch();
  const { code } = useParams();
  const { push: navigate } = useHistory();
  const [loader, setLoader] = useState(false);
  const [userPartners, setUserPartners] = useState(null);

  const {
    page,
    limit,
    getPageState,
    setPageState,
    next,
    previous,
  } = usePagination({
    key: 'partner'
  });

  const loadUserPartners = useCallback(async () => {
    try {
      setLoader(true);
      const response = await PartnerApi.getUserPartners({
        code,
        limit,
        cursor: getPageState(),
      });
      setPageState(response.metadata.pageState);
      const newUserPartners = response.data.map(v => new UserModel({
        user_id: v.user_id,
        user_name: v.user_details?.user_name,
        userSettings: new UserSettingsModel({
          image_url: v.user_details?.user_image,
          bio_short: v.user_details?.bio_short,
          sale_started: v.user_details?.sale_started,
        })
      }));
      setUserPartners(newUserPartners);
    } catch (error) {
      console.log(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The redemption code users can't be loaded",
      }));
    } finally {
      setLoader(false);
    }
  }, [code, limit, dispatch, setPageState, getPageState]);

  useEffect(() => {
    loadUserPartners();
  }, [loadUserPartners]);

  const paginationNextClick = useCallback(() => {
    next();
    loadUserPartners();
  }, [next, loadUserPartners]);

  const paginationPreviousClick = useCallback(() => {
    previous();
    loadUserPartners();
  }, [previous, loadUserPartners]);

  const userCardClick = useCallback((partnerUser) => () => {
    navigate(`/users/${partnerUser.user_id}`);
  }, [navigate]);

  return (
    <Layout title={`Partner: ${code}`}>
      <Container gap="10px">
        <Loader loading={loader} />
        <Container gap="10px">
          {userPartners?.map(userPartner => (
            <UserCard
              user={userPartner}
              isDeleteButton={false}
              onClick={userCardClick(userPartner)}
            />
          ))}
        </Container>
        {!loader && (
          <Pagination
            page={page}
            isNext={userPartners?.length === 10}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
      </Container>
    </Layout>
  );
};  