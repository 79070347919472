import { urlSearchParamSetter } from "../utilities";
import { http, httpOfRecrd } from "./http";

/** @typedef {import('../models/post.model').PostRecrdModel} PostRecrdModel */

export class PostApi {
  /**
   * @param {object} props 
   * @param {number} props.page
   * @param {number} props.limit
   * @param {Recrd<string, string>} props.filter
   * @returns {Promise<import('../models/post.model').PostModel[]>}
   */
  static async getPosts(props) {
    const {
      authorId,
      postId,
      title,
      postType,
      saleStatus,
      meta,
      includes,
      limit,
      pageState,
    } = props;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('postId', postId);
    setter('authorId', authorId);
    setter('title', title);
    setter('postType', postType);
    setter('saleStatus', saleStatus);
    setter('meta', meta);
    setter('includes', includes);
    setter('limit', limit);
    setter('pageState', pageState);
    const response = await http.get(`/posts?${params}`);
    return response.data;
  }
  
  /**
   * 
   * @param {object} props 
   * @param {string} props.title
   * @returns {Promise<{ data: PostRecrdModel[] }>}
   */
  static async getPostsOfRecrd({
    title,
  }) {
    const params = new URLSearchParams({
      q: title,
      offset: 0,
      limit: 10,
    })
    const response = await httpOfRecrd.get(
      `/engage_search/v3/posts?${params}`,
    );
    return {
      data: response.data,
    };
  }

  static async getPostsByIdOfRecrd({ postIds }) {
    const response = await httpOfRecrd.post(
      `/post_feed_posts/v3/list`,
      {
        post_ids: postIds
      }
    );
    return response.data;
  }

  static async getHashtags(data) {
    const {
      authorId
    } = data;
    const params = new URLSearchParams()
    const setter = urlSearchParamSetter(params);
    setter('authorId', authorId);
    const response = await http.get(`/posts/hashtags?${params}`);
    return response.data;
  }

  /**
   * @param {object} props 
   * @param {string} props.postId
   * @returns {void}
   */
  static async deletePost(props) {
    const {
      postId
    } = props;
    await httpOfRecrd.delete(
      `/post_feed_posts/v3/${postId}`,
    );
  }

  static async moderatePost({
    postId,
    action,
  }) {
    await http.post('/posts/moderation', {
      postId,
      action,
    });
  }
}