import React from 'react';
import styled from '@emotion/styled';
import {
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { TextColors } from '../../../constants';
import { Avatar } from '../../../components/Avatar';
import { useUtility } from '../../../utilities/hooks';
import { getFormattedTextByIsoString } from '../../../utilities';

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 90px;
  margin: 0 0 25px 0;
  cursor: pointer;
`;

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../models/feedback.model').FeedbackModel} FeedbackModel */

/**
 * @param {object} props
 * @param {FeedbackModel} props.feedback
 * @param {() => void} props.onClick
 * @param {() => void} props.onCopy
 * @returns {ReactNode}
 */
export const FeedbackCard = ({
  feedback,
}) => {
  const {
    copyClick,
    navigateClick,
  } = useUtility();

  return (
    <Container>
      <Box>
        <Avatar 
          image={feedback?.user?.user_image} 
          userName={feedback?.user_name} 
          onPress={navigateClick(`/users/${feedback?.user_id}`)} 
        />
      </Box>
      <Box 
        flexDirection="column" 
        flex="1" 
        padding="0 0 0 20px" 
        onClick={navigateClick(`/users/${feedback?.user_id}`)}
      >
        <Box>
          <Text color={TextColors.Name2}>
            {feedback?.user?.user_fullname ?? <>&lt;No Full Name&gt;</>}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{feedback?.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Subtext}>
            {feedback?.user?.bio_short ?? <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" flex="1" onClick={navigateClick(`/feedback/${feedback.feedback_id}`)}>
        <Box>
          <Text color={TextColors.Message}>{feedback.message ?? '<No message>'}</Text>
        </Box>
      </Box>
      <Box flexDirection="column" flex="1">
        <Box whiteSpace="nowrap">
          <Text>
            Feedback ID:
          </Text>
          <Text color={TextColors.ID}>
            {feedback?.feedback_id}
          </Text>
          <Box>
            <StyledCopyIcon onClick={copyClick(feedback.feedback_id)} />
          </Box>
        </Box>
        <Box gap="5px">
          <Text>
            Created:
          </Text>
          <Text color={TextColors.Subtext}>
            {getFormattedTextByIsoString(feedback.created_at) ?? <>N/A</>}
          </Text>
        </Box>
        <Box gap="5px">
          <Box>
            <Text>
              Attributes:
            </Text>
          </Box>
          <Box position="relative" top="3px">
            <Text 
              display="block" 
              color={TextColors.List} 
              width="350px" 
              wordWrap="break-all"
              size="14px"
            >
              {feedback.user_attributes.join(',')}
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};