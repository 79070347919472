import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../store/ui";
import { useHistory } from "react-router-dom";
import { PaginationAction, SnackbarTypes } from "../constants";

export const useStateRef = (defaultValue) => {
  const valueRef = useRef(defaultValue);
  const [value, setvalue] = useState(defaultValue);

  const setValue = useCallback((newValue) => {
    valueRef.current = newValue;
    if (typeof newValue === 'function') {
      setvalue(prevValue => {
        return newValue(prevValue);
      });
    } else {
      setvalue(newValue);
    }
  }, []);

  return [value, setValue, valueRef];
};

export const useUtility = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  /** @type {{ push: (path: string) => void }} */
  const { push: navigate } = history;

  const copy = useCallback((value, message) => {
    window.navigator.clipboard.writeText(value);
    dispatch(setSnackbar({
      open: true,
      type: SnackbarTypes.Info,
      content: message,
    }));
  }, [dispatch]);

  const copyClick = useCallback(
    (value, message) => () => copy(value, message),
    [copy],
  );

  const navigateClick = useCallback((path) => () => {
    navigate(path);
  }, [navigate]);

  return {
    navigate,
    copy,
    copyClick,
    navigateClick,
  }
}

export const usePagination = (props = {
  key: undefined,
  defaultPageState: undefined,
  defaultPage: 1,
  defaultLimit: 10,
  action: PaginationAction.Next,
  states: [],
}) => {
  const key = props?.key;
  const defaultPageState = props?.defaultPageState ?? undefined;
  let defaultPage = props?.defaultPage ?? 1;
  let defaultLimit = props?.defaultLimit ?? 10;
  let action = props?.action ?? PaginationAction.Next;
  let states = props?.states ?? [];

  const storage = JSON.parse(localStorage.getItem('pagination') ?? '{}');
  if (key && storage[key]) {
    defaultPage = storage[key].defaultPage ?? defaultPage;
    defaultLimit = storage[key].defaultLimit ?? defaultLimit;
    action = storage[key].action;
    states = storage[key].states;
  }

  const storePagination = useCallback((field, value) => {
    if (key === undefined) {
      return;
    }
    const storage = JSON.parse(localStorage.getItem('pagination') ?? '{}');
    storage[key] = storage[key] ?? {};
    storage[key][field] = value;
    localStorage.setItem('pagination', JSON.stringify(storage));
  }, [key]);

  const [page, setpage] = useState(defaultPage);
  const [limit, setlimit] = useState(defaultLimit);

  const setLimit = useCallback((valueOrFunc) => {
    if (typeof valueOrFunc === 'function') {
      setlimit(value => {
        const result = valueOrFunc(value);
        storePagination('defaultLimit', result);
        return result;
      })
      return;
    }
    storePagination('defaultLimit', valueOrFunc);
    setlimit(valueOrFunc);
  }, [storePagination]);

  const setPage = useCallback((valueOrFunc) => {
    if (typeof valueOrFunc === 'function') {
      setpage(value => {
        const result = valueOrFunc(value);
        storePagination('defaultPage', result);
        return result;
      })
      return;
    }
    storePagination('defaultPage', valueOrFunc);
    setpage(valueOrFunc);
  }, [storePagination]);

  const data = useRef({
    action,
    states,
  });

  const setAction = useCallback((action) => {
    data.current.action = action;
    storePagination('action', data.current.action);
  }, [storePagination]);

  const getPageState = useCallback((ignore = false) => {
    if (ignore) {
      return defaultPageState;
    }
    if (data.current.action === PaginationAction.Previous) {
      data.current.states.pop();
      data.current.states.pop();
    }
    storePagination('states', data.current.states);
    const cursor = data.current.states[data.current.states.length - 1] ?? defaultPageState;
    return cursor;
  }, [defaultPageState, storePagination]);

  const setPageState = useCallback((pageState) => {
    if (data.current.action === PaginationAction.Next) {
      data.current.states.push(pageState);
    }
    storePagination('states', data.current.states);
  }, [storePagination]);

  const reset = useCallback(() => {
    data.current = {
      action: PaginationAction.Next,
      states: [],
    };
    setPage(1);
    setLimit(10);
  }, [setPage, setLimit]);

  const next = useCallback(() => {
    setAction(PaginationAction.Next);
    setPage(page => page + 1);
    setLimit(limit => limit);
  }, [setPage, setLimit, setAction]);

  const previous = useCallback(() => {
    setAction(PaginationAction.Previous);
    setPage(page => page > 1 ? page - 1 : page);
    setLimit(limit => limit);
  }, [setPage, setLimit, setAction]);

  return {
    data,
    page,
    limit,
    setPage,
    setLimit,
    setPageState,
    getPageState,
    setAction,
    next,
    previous,
    reset,
  }
}