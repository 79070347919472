import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { FormTypes, TextFontSizes } from '../../../constants';
import { Row } from '../../../components/Row';
import { Text } from '../../../components/Text';
import { useFormikContext } from 'formik';
import { Container } from '../../../components/Container';

export const Form = ({
  formType = FormTypes.Create,
  isOpen = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
  } = useFormikContext();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>
        <Row gap="5px">
          <Text size={TextFontSizes.Large}>
            Editorial Post: 
          </Text>
          <Text size={TextFontSizes.Large}>
            {formType === FormTypes.Create && 'New'}
            {formType === FormTypes.Update && 'Change'}
          </Text>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Container gap="10px" width="300px">
          <Row>
            <TextField
              fullWidth
              label="Post ID"
              name="postId"
              value={values.postId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postId && errors.postId}
              helperText={touched.postId && errors.postId}
            />
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          disabled={!isValid}
          variant="outlined"
          color="success"
          onClick={onSubmit}
        >
          {formType === FormTypes.Create && 'Create'}
          {formType === FormTypes.Update && 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}