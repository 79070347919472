import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Row } from '../../components/Row';
import { Text } from '../../components/Text';
import { Layout } from '../../components/Layout';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Table, Tabs, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { SnackbarTypes } from '../../constants';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { TableBody } from '../../components/Table/TableBody';
import { useFormik } from 'formik';
import { Box } from '../../components/Box';
import { FeedbackApi } from '../../api/feedback.api';
import {
  ContentCopy,
  OpenInNew,
} from '@mui/icons-material';
import { useUtility } from '../../utilities/hooks';
import { FeedbackStatuses } from '../../constants/feedback-statuses';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

const TABS = {
  Detail: 'detail',
  Action: 'action',
}

export const Feedback = () => {
  const dispatch = useDispatch();
  const { navigateClick, copyClick } = useUtility();
  const { feedbackId } = useParams();
  const [tab, setTab] = useState(TABS.Detail);
  /** @type {[FeedbackModel, (v: FeedbackModel) => void]} */
  const [feedback, setFeedback] = useState(null);
  const [loader, setLoader] = useState(true);

  const form = useFormik({
    initialValues: {
      description: '',
      status: '',
    },
  });

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
  } = form;

  const loadFeedback = useCallback(async (loader = true) => {
    try {
      if (loader) setLoader(true);
      const { data } = await FeedbackApi.getFeedbacks({ 
        feedback_id: feedbackId,
      });
      const [newFeedback] = data;
      setFeedback(newFeedback);
    } catch (error) {
      console.error(error);
    } finally {
      if (loader) setLoader(false);
    }
  }, [feedbackId]);

  useEffect(() => {
    if (!feedback) {
      return;
    }
    setValues({
      description: feedback.description,
      status: feedback.status,
    })
  }, [feedback, setValues]);

  useEffect(() => {
    loadFeedback();
  }, [loadFeedback]);

  const tabsChange = useCallback((_, newTab) => {
    setTab(newTab);
  }, []);

  const actionSaveClick = useCallback(async () => {
    try {
      const {
        status,
        description,
      } = values;
      await FeedbackApi.updateFeedback(feedback.feedback_id, {
        description: description === undefined || description === ''
          ? undefined : description,
        status: status === undefined || status === ''
          ? undefined : status,
      })
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'The feedback has been updated',
      }));
      await loadFeedback(false);
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: The feedback hasn\'t been updated',
      }));
    }
  }, [feedback, values, loadFeedback, dispatch]);

  return (
    <Layout title="Feedback Detail">
      <Container>
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
        <Row>
          <Text>Feedback</Text>
        </Row>
        {!loader && feedback && (
          <Container>
            <Container>
              <TabContext value={tab}>
                <Tabs value={tab} onChange={tabsChange}>
                  <Tab label="DETAIL" value={TABS.Detail} />
                  <Tab label="ACTION" value={TABS.Action} />
                </Tabs>
                <TabPanel value={TABS.Detail}>
                  <Table>
                    <TableBody backgroundColor="transparent">
                      <TableRow>
                        <TableCell>
                          <Text>Subject:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{feedback.subject ?? '<No subject>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>Message:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{feedback.message ?? '<No message>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>Status:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{feedback.status ?? '<No status>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>Created:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{feedback.created_at ?? '<N/A>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User Name</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {feedback.user_name}
                            </Text>
                            <OpenInNew onClick={navigateClick(`/users/${feedback.user_id}`)} />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User ID</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {feedback.user_id}
                            </Text>
                            <ContentCopy onClick={copyClick(feedback.user_id, 'the user ID has been copied')} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabPanel>
                <TabPanel value={TABS.Action}>
                  <Table>
                    <TableBody backgroundColor="transparent">
                      <TableRow>
                        <Container gap="10px">
                          <Row>
                            <Text>Description</Text>
                          </Row>
                          <Row>
                            <TextField
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              placeholder="Description"
                              multiline
                              minRows={4}
                            />
                          </Row>
                        </Container>
                      </TableRow>
                      <TableRow>
                        <Container gap="10px">
                          <Row>
                            <Text>Status</Text>
                          </Row>
                          <Row>
                            <FormControl fullWidth>
                              <InputLabel id="select-label-status">Status</InputLabel>
                              <Select
                                labelId="select-label-status"
                                label="Status"
                                name="status"
                                value={values.status}
                                onChange={handleChange}
                              >
                                {Object.keys(FeedbackStatuses).map(k => (
                                  <MenuItem key={k} value={FeedbackStatuses[k]}>{k}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Container>
                      </TableRow>
                      <TableRow>
                        <Row justifyContent="flex-end" padding="10px 0 10px 0">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={actionSaveClick}
                          >
                            Save
                          </Button>
                        </Row>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabPanel>
              </TabContext>
            </Container>
          </Container>
        )}
      </Container>
    </Layout>
  )
};