import React, { useCallback, useMemo } from 'react';
import { Container } from "../../../../components/Container";
import { useUtility } from '../../../../utilities/hooks';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { getChipColorOfPostStatus, getPostStatusLabel } from '../../../../utilities';
import { UserCard } from '../../../../components/UserCard';
import styled from '@emotion/styled';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import uuidTime from 'uuid-time';
import { format } from 'date-fns';
import { Chip } from '@mui/material';

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Image = styled.img`
  display: flex;
  width: 360px;
  height: 205px;
  object-fit: contain;
  border-radius: 6px;
  border: 1px solid #373737;
`;

/** @typedef {import('../../../../models/post.model').PostModel} PostModel */

/**
 * @param {object} props
 * @param {PostModel} props.post
 * @returns 
 */
export const Details = ({
  post,
}) => {
  const {
    copyClick,
    navigate,
  } = useUtility();

  const createdOn = useMemo(() => {
    const time = uuidTime.v1(post.post_id);
    if (!time) {
      return <>No date and time</>;
    }
    const date = new Date(time);
    if (isNaN(date.getTime())) {
      return <>No date and time</>
    }
    return <>{date.toISOString()}</>;
  }, [post]);

  const videoLength = useMemo(() => {
    if (!post.video_length_ms) {
      return 'N/A';
    }
    const date = new Date(post.video_length_ms);
    return format(date, 'HH:mm:ss');
  }, [post]);

  const userClick = useCallback(() => {
    navigate(`/users/${post.author_id}`)
  }, [post, navigate]);

  return (
    <Container>
      <Container>
        <Box>
          <Text>{post.height}x{post.width}|{videoLength}</Text>
        </Box>
        <Box>
          <Text>Created On: {createdOn}</Text>
        </Box>
        <Box>
          <Text>Type: {post.post_type}</Text>
        </Box>
        <Box>
          <Text>Status: 
            <Chip 
              label={getPostStatusLabel(post?.post_status ?? '', true)} 
              color={getChipColorOfPostStatus(post?.post_status ?? '')}
              size="small" 
            />
          </Text>
        </Box>
        <Box>
          <Text>Device Meta: {post.device_meta}</Text>
        </Box>
        <Box>
          <Text>Post Sell Status: {post.sale_status}</Text>
        </Box>
        <Box>
          <Text>Post ID: {post.post_id}</Text>
          <Box>
            <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
          </Box>
        </Box>
        {post.post_origin_id && (
          <Box>
            <Text>Origin Post ID: {post.post_origin_id}</Text>
            <Box>
              <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
            </Box>
          </Box>
        )}
        {post.post_parent_id && (
          <Box>
            <Text>Parent Post ID: {post.post_parent_id}</Text>
            <Box>
              <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
            </Box>
          </Box>
        )}
      </Container>
      <Box gap="5px">
        <Box flexDirection="column">
          <Box>
            <Text>First Frame</Text>
          </Box>
          <Box>
            <Image src={post.first_frame} alt="" />
          </Box>
        </Box>
        <Box flexDirection="column">
          <Box>
            <Text>Thumbnail Image</Text>
          </Box>
          <Box>
            <Image src={post.thumb_url} alt="" />
          </Box>
        </Box>
        <Box flexDirection="column">
          <Box>
            <Text>Rebound GIF</Text>
          </Box>
          <Box>
            <Image src={post.rebound_image} alt="" />
          </Box>
        </Box>
      </Box>
      <Container>
        <UserCard 
          user={post.user} 
          isDeleteButton={false}
          onClick={userClick}
          onCopy={copyClick(post.post_id, 'The user ID has been copied')}
        />
      </Container>
    </Container>
  )
}