import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from './Header';
import { Content } from './Content';
import { PostApi } from '../../api/post.api';
import { Layout } from '../../components/Layout';
import { ReactionApi } from '../../api/reaction.api';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import { AwsApi } from '../../api/Aws.api';
import { TABS } from './constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Row } from '../../components/Row';
import { Box } from '../../components/Box';
import { setSnackbar } from '../../store/ui';
import { SnackbarTypes } from '../../constants';
import { useDispatch } from 'react-redux';

/** @typedef {import('../../models/post.model').PostModel} PostModel  */
/** @typedef {import('../../models/comment.model').CommentModel} CommentModel  */
/** @typedef {import('../../models/reaction.model').ReactionModel} ReactionModel  */
/** @typedef {import('../../models/s3-post.model').S3PostModel} S3PostModel */

/**
 * @returns {import('react').ReactNode}
 */
export const Post = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const postId = params.postId;
  const queryAsText = window.location.search;
  const query = useMemo(() => {
    return new URLSearchParams(queryAsText);
  }, [queryAsText]);
  /** @type {[PostModel, (v: PostModel) => void]} */
  const [post, setPost] = useState(null);
  const [details, setDetails] = useState(null);
  /** @type {[S3PostModel[], (v: S3PostModel[]) => void]} */
  const [s3Posts, setS3Posts] = useState([]);
  const [reaction, setReaction] = useState(null);
  const [loader, setLoader] = useState(true);
  const [loadingOfS3Posts, setLoadingOfS3Posts] = useState(true);
  const [tab, setTab] = useState(query.get('tab') ?? TABS.DETAILS);

  const tabChange = useCallback((_, newTab) => {
    setTab(newTab);
  }, []);

  const loadPostDetails = useCallback(async (postId) => {
    try {
      const data = await PostApi.getDetails({
        postId
      });
      setDetails(data)
    } catch (error) {
      console.error(error);
    }
  }, []);

  const loadPostData = useCallback(async (postId) => {
    loadPostDetails(postId);
    ReactionApi.getReactions({ 
      objectId: postId,
      includes: ['user'],
    }).then(({ reaction }) => {
      setReaction(reaction);
    });
    setLoadingOfS3Posts(true);
    AwsApi.getS3Posts({
      postId,
    }).then(({ s3Posts }) => {
      setS3Posts(s3Posts);
    }).finally(() => {
      setLoadingOfS3Posts(false);
    });
  }, [loadPostDetails]);

  useEffect(() => {
    const handler = async () => {
      try {
        setLoader(true);
        const { posts } = await PostApi.getPosts({ 
          postId,
          includes: ['user', 'user.userSettings', 'saleCreatorUser', 'saleCreatorUser.userSettings'],
        });
        loadPostData(postId);
        setPost(posts[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };
    handler();
  }, [postId, loadPostData]);

  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
  const [campaignDialogValue, setCampaignDialogValue] = useState('');

  const campaignLinkClick = useCallback(() => {
    setCampaignDialogOpen(true);
  }, []);

  const campaginaDialogClose = useCallback(() => {
    setCampaignDialogOpen(false);
    setCampaignDialogValue('');
  }, []);

  const campaignDialogChange = useCallback((event) => {
    const { target: { value: newCampaginDialogValue } } = event;
    setCampaignDialogValue(newCampaginDialogValue); 
  }, []);

  const campaginDialogSubmit = useCallback(() => {
    const params = new URLSearchParams({ 
      post_id: postId,
      ref: campaignDialogValue,
    });
    const link = `${process.env.REACT_APP_RECRD_WEB_URL}/discover?${params}`;
    window.navigator.clipboard.writeText(link);
    dispatch(setSnackbar({
      open: true,
      type: SnackbarTypes.Info,
      content: 'Campaign link has been copied to the clipbaord',
    }));
    setCampaignDialogOpen(false);
    setCampaignDialogValue('');
  }, [dispatch, postId, campaignDialogValue]);

  return (
    <Layout title="Post">
      <Container>
        <Loader loading={loader} />
        {!loader && post && (
          <>
            <Header 
              post={post} 
              onCampaignLinkClick={campaignLinkClick}
            />
            <Content 
              post={post} 
              details={details}
              s3Posts={s3Posts}
              loadingOfS3Posts={loadingOfS3Posts}
              reaction={reaction}
              tab={tab}
              onTabChange={tabChange}
            />
          </>
        )}
      </Container>
      <Dialog
        open={campaignDialogOpen}
        onClose={campaginaDialogClose}
      >
        <DialogTitle>
          Campaign Link
        </DialogTitle>
        <DialogContent>
          <Row padding="20px 0">
            <TextField
              fullWidth
              label="Reference"
              value={campaignDialogValue}
              onChange={campaignDialogChange}
              error={campaignDialogValue.length === 0}
              helperText={campaignDialogValue.length === 0 && "the reference can't be empty"}
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Box gap="10px">
            <Button
              variant="contained"
              color="error"
              onClick={campaginaDialogClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              disabled={campaignDialogValue.length === 0}
              onClick={campaginDialogSubmit}
            >
              Create
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Layout>
  )
};