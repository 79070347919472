import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Avatar } from '../Avatar';
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import uuidTime from 'uuid-time';
import { Box } from '../Box';
import { Text } from '../Text';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { hasFeatures } from '../../store/auth';
import { Row } from '../Row';
import { useUtility } from '../../utilities/hooks';
import { TextColors } from '../../constants';

const StyledDeleteIcon = styled(DeleteIcon)`
  fill: #c62828;
  cursor: pointer;
`;

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 25px 0;
  cursor: pointer;
`;

/** @typedef {import('../../models/user.model').UserModel} UserModel */

/**
 * @param {object} props
 * @param {UserModel} props.user
 * @param {boolean} props.isDeleteButton
 * @param {() => void} props.onClick
 * @param {() => void} props.onDelete
 * @returns {import('react').ReactNode}
 */
export const UserCard = ({
  user,
  isDeleteButton,
  onClick,
  onDelete,
}) => {
  const isEmail = useSelector(hasFeatures(['read-email']));
  const isPhone = useSelector(hasFeatures(['read-phone']));
  const isDob = useSelector(hasFeatures(['read-dob']));

  const userSettings = user?.userSettings;

  const attributes = useMemo(() => {
    if (!userSettings || !userSettings.attributes) {
      return <>None</>;
    }
    const list = Object.keys(userSettings.attributes)
      .filter(key => userSettings.attributes[key] === '1')
      .map(key => key);
    if (!list.length) {
      return <>None</>
    }
    return list.join(', ');
  }, [userSettings]);

  const lastLogin = useMemo(() => {
    if (!user || !user.last_login_tms) {
      return <>N/A</>
    }
    const time = uuidTime.v1(user.last_login_tms);
    const date = new Date(time);
    if (isNaN(date.getTime())) {
      return <>N/A</>
    }
    return <>{format(date, 'dd MMM yyyy - HH:mm:ss')}</>;
  }, [user]);

  const avatarClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const infoClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const deleteClick = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const {
    copyClick
  } = useUtility();

  return (
    <Container>
      <Box>
        <Avatar 
          image={user?.userSettings?.image_url} 
          userName={user?.user_name} 
          onPress={avatarClick} 
        />
      </Box>
      <Box flexDirection="column" flex="1" padding="0 0 0 20px" onClick={infoClick}>
        <Box>
          <Text color={TextColors.Title}>
            {user?.full_name ?? <>&lt;No Full Name&gt;</>}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{user?.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Subtext}>
            {userSettings?.bio_short ?? <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" flex="1">
        <Box gap="5px">
          <Text>
            Email:
          </Text>
          <Text color={TextColors.Email}>
            {isEmail && (
              <>{user?.email_address ?? <>N/A</>}</>
            )}
            {!isEmail && (
              <>No permission</>
            )}
          </Text>
        </Box>
        <Box gap="5px">
          <Text>
            Phone:
          </Text>
          <Text color={TextColors.Phone}>
            {isPhone && (
              <>{user?.phone_number ?? <>N/A</>}</>
            )}
            {!isPhone && (
              <>No permission</>
            )}
          </Text>
        </Box>
        <Box gap="5px">
          <Text>
            Date of Birth:
          </Text>
          <Text color={TextColors.DateTime}>
            {isDob && (
              <>{user?.dob ?? <>N/A</>}</>
            )}
            {!isDob && (
              <>No permission</>
            )}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" flex="1">
        <Box gap="5px" whiteSpace="nowrap">
          <Text>
            User ID:
          </Text>
          <Text color={TextColors.ID}>
            {user?.user_id}
          </Text>
          <Box>
            <StyledCopyIcon onClick={copyClick(user?.user_id, 'The user ID has been copied')} />
          </Box>
        </Box>
        <Box gap="5px">
          <Text>
            Last Login:
          </Text>
          <Text color={TextColors.DateTime}>
            {lastLogin ?? <>N/A</>}
          </Text>
        </Box>
        <Box gap="5px">
          <Box>
            <Text>
              Attributes:
            </Text>
          </Box>
          <Box position="relative">
            <Text color={TextColors.List}>
              {attributes}
            </Text>
          </Box>
        </Box>
        <Row gap="5px">
          <Text>
            NFT:
          </Text>
          <Text color={TextColors.Choice}>
            {userSettings?.sale_started ? 'Yes' : 'No'}
          </Text>
        </Row>
      </Box>
      <Box flex="0" padding="0 0 0 20px">
        {isDeleteButton && (
          <StyledDeleteIcon onClick={deleteClick} />
        )}
      </Box>
    </Container>
  );
};