import React, { useCallback } from 'react';
import { Table } from "@mui/material";
import { Container } from "../../../../components/Container"
import { TableBody } from '../../../../components/Table/TableBody';
import { TableRow } from '../../../../components/Table/TableRow';
import { TableCell } from '../../../../components/Table/TableCell';
import { SnackbarTypes } from '../../../../constants';
import { UserCard } from '../../../../components/UserCard';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../store/ui';
import { useHistory } from 'react-router-dom';
import { getSaleStatus } from '../../../../utilities';

/** @typedef {import('../../../../models/post.model').PostModel} PostModel */

/** 
 * @typedef {object} NTFProps
 * @property {PostModel} post
 */

/**
 * @param {NTFProps} props 
 * @returns {JSX.Element}
 */
export const NFT = ({
  post
}) => {
  const dispatch = useDispatch();
  const { push: navigate } = useHistory();

  const userCardClick = useCallback(() => {
    window.navigator.clipboard.writeText(post.post_id);
    dispatch(setSnackbar({
      open: true,
      type: SnackbarTypes.Info,
      content: post.post_id,
    }));
  }, [post, dispatch]);

  const userCardCopy = useCallback(() => {
    navigate(`/users/${post.sale_creator_user_id}`);
  }, [post, navigate]);

  if (!post) {
    return null;
  }

  return (
    <Container gap="10px">
      <Table>
        <TableBody backgroundColor="transparent">
          <TableRow>
            <TableCell>
              Sale Status:
            </TableCell>
            <TableCell>
              {getSaleStatus(post?.sale_status) ?? 'Nothing'}
            </TableCell>
          </TableRow>
          {post?.sale_status > 0 && (
            <>
              <TableRow>
                <TableCell>
                  Sale Price:
                </TableCell>
                <TableCell>
                  {post.sale_price}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Sale datestamp:
                </TableCell>
                <TableCell>
                  {post.sale_datestamp}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Sale creator commission (%):
                </TableCell>
                <TableCell>
                  {post.sale_creator_commission_percentage}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Sale Creator User ID:
                </TableCell>
                <TableCell>
                  {post.sale_creator_user_id}
                </TableCell>
              </TableRow>
              {post.saleCreatorUser && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <UserCard
                      user={post.saleCreatorUser}
                      isDeleteButton={false}
                      onClick={userCardClick}
                      onCopy={userCardCopy}
                    />
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}