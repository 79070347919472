
export class EditorialPostModel {
  /** @type {string} uuid */
  postId
  /** @type {number} int */
  postViews;
  /** @param {Partial<EditorialPostModel>} */
  constructor(data) {
    Object.assign(this, data);
  }
}