import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  CircularProgress, 
} from '@mui/material';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { FeedbackCard } from './FeedbackCard';
import { FeedbackApi } from '../../api/feedback.api';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'Feedback ID',
    value: 'feedback_id',
  },
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Subject',
    value: 'subject',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

/** @typedef {import('../../models/feedback.model').FeedbackModel} FeedbackModel */

export const Feedbacks = () => {
  const dispatch = useDispatch();
  /** @type {History} */
  /** @type {[FeedbackModel[], (value: FeedbackModel[]) => void]} */
  const [feedbacks, setFeedbacks] = useState([]);
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'feedbacks',
    item: searchItems[0],
    text: '',
  });

  const {
    page,
    limit,
    next,
    previous,
  } = usePagination({
    key: 'feedbacks'
  });

  const loadFeedbacks = useCallback(async () => {
    try {
      setLoader(true);
      const offset = (page - 1) * limit;
      const { data } = await FeedbackApi.getFeedbacks({
        user_id: getSearchTextByItemValue('user_id'),
        user_name: getSearchTextByItemValue('user_name'),
        feedback_id: getSearchTextByItemValue('feedback_id'),
        status: getSearchTextByItemValue('status'),
        subject: getSearchTextByItemValue('subject'),
        limit,
        offset,
        searchTextAsDebounced,
      });
      setFeedbacks(data);
    } catch (error) {
      console.error(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The feedbacks can't be loaded",
      }))
    } finally {
      setLoader(false);
    }
  }, [
    page,
    limit,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    dispatch,
  ]);

  useEffect(() => {
    loadFeedbacks();
  }, [loadFeedbacks]);

  const paginationNextClick = useCallback(() => {
    next();
  }, [next]);

  const paginationPreviousClick = useCallback(() => {
    previous();
  }, [previous]);

  return (
    <Layout title="Feedback">
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <ListPanel>
          {feedbacks.map(feedback => (
            <FeedbackCard
              key={feedback.feedback_id}
              feedback={feedback}
            />
          ))}
        </ListPanel>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
      </Container>
    </Layout>
  );
};  