import React from 'react';
import styled from '@emotion/styled';
import {
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { TextColors } from '../../../constants';
import { Avatar } from '../../../components/Avatar';
import { getReportReason } from '../../../utilities';
import { useUtility } from '../../../utilities/hooks';

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 25px 0;
  cursor: pointer;
`;

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../models/reported-user.model').ReportedUserModel} ReportedUserModel */

/**
 * @param {object} props
 * @param {ReportedUserModel} props.report
 * @returns {ReactNode}
 */
export const ReportedUserCard = ({
  report,
}) => {
  const {
    copyClick,
    navigateClick,
  } = useUtility();

  return (
    <Container>
      <Box>
        <Avatar 
          image={report.reporterId.user_image} 
          userName={report.reporterId.user_name} 
          onPress={navigateClick(`/users/${report.reporterId.user_id}`)} 
        />
      </Box>
      <Box 
        flexDirection="column" 
        padding="0 0 0 20px" 
        minWidth="150px"
        onClick={navigateClick(`/users/${report.reporterId.user_id}`)}
      >
        <Box>
          <Text color={TextColors.Name2}>
            {report.reporterId.user_fullname ?? <>&lt;No Full Name&gt;</>}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{report.reporterId.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Subtext}>
            {report.reporterId.bio_short ?? <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box>
        <Avatar 
          image={report.userId.user_image} 
          userName={report.userId.user_name} 
          onPress={navigateClick(`/users/${report.userId.user_id}`)} 
        />
      </Box>
      <Box 
        flexDirection="column" 
        padding="0 0 0 20px" 
        onClick={navigateClick(`/users/${report.userId.user_id}`)}
      >
        <Box>
          <Text color={TextColors.Name2}>
            {report.userId.user_fullname ?? <>&lt;No Full Name&gt;</>}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{report.userId.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Subtext}>
            {report.userId.bio_short ?? <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" padding="0 20px" onClick={navigateClick(`/reports/users/${report.reportId}`)}>
        <Box flexDirection="column">
          <Text>Status:</Text>
          <Text color={TextColors.Status}>{report.status}</Text>
        </Box>
        <Box flexDirection="column">
          <Text>Reason:</Text>
          <Text color={TextColors.Status2}>{getReportReason(report.reason)}</Text>
        </Box>
      </Box>
      <Box flexDirection="column">
        <Box flexDirection="column" flexWrap="wrap">
          <Box gap="5px">
            <Text>
              User ID of Reporter:
            </Text>
            <Box>
              <StyledCopyIcon 
                onClick={copyClick(report.reporterId.user_id, 'The user ID of reporter has been copied')} 
              />
            </Box>
          </Box>
          <Text color={TextColors.ID}>
            {report.reporterId.user_id}
          </Text>
        </Box>
        <Box flexDirection="column" flexWrap="wrap">
          <Box gap="5px">
            <Text>
              User ID:
            </Text>
            <Box>
              <StyledCopyIcon 
                onClick={copyClick(report.userId.user_id, 'The user ID has been copied')} 
              />
            </Box>
          </Box>
          <Text color={TextColors.ID2}>
            {report.userId.user_id}
          </Text>
        </Box>
      </Box>
    </Container>
  );
};