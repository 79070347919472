import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { SnackbarTypes } from '../../constants';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import { EditorialApi } from '../../api/editorial.api';
import { EditorialPlacementModel } from '../../models/editorial-placement.model';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { useUtility } from '../../utilities/hooks';
import { TableCell } from '../../components/Table/TableCell';
import { TableRow } from '../../components/Table/TableRow';

/** @typedef {import('../../models/editorial-zone.model').EditorialZoneModel} EditorialZoneModel */
/** @typedef {import('../../models/editorial-placement.model').EditorialPlacementModel} EditorialPlacementModel */

const Placements = [
  'featured_1',
  'featured_2',
  'featured_3',
  'editorial_1',
  'editorial_2',
  'editorial_3',
  'editorial_4',
]

export const EditorialPlacements = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  /** @type {[EditorialPlacementModel[], (v: EditorialPlacementModel[]) => void]} */
  const [placements, setPlacements] = useState([]);
  const {
    navigateClick,
  } = useUtility();

  const loadEditorialZones = useCallback(async () => {
    try {
      setLoading(true);
      const newZones = await EditorialApi.getEditorialZones();
      const newPlacements = Placements.map(placement => {
        const countOfZones = newZones.filter(v => v.placement === placement)
          .reduce((sum, v) => sum + v.data.length, 0);
        const countOfPosts = newZones.filter(v => v.placement === placement)
          .flatMap(v => v.data)
          .reduce((sum, v) => sum + v.zonePostCount, 0);
        return new EditorialPlacementModel({
          placement,
          countOfZones,
          countOfPosts,
        })
      })
      setPlacements(newPlacements);
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The editorial zones can't be loaded",
      }))
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadEditorialZones();
  }, [loadEditorialZones]);

  return (
    <Layout title="Editorial Placements">
      <Container>
        <Container gap="10px">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Placement
                </TableCell>
                <TableCell>
                  Zone Count
                </TableCell>
                <TableCell>
                  Post Count
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {placements.map((v, i) => (
                <TableRow key={v.placement + i}>
                  <TableCell>
                    {v.placement}
                  </TableCell>
                  <TableCell>
                    {v.countOfZones}
                  </TableCell>
                  <TableCell>
                    {v.countOfPosts}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={navigateClick(`/editorial/${v.placement}`)}
                    >
                      Open
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Container>
        <Loader loading={loading} />
      </Container>
    </Layout>
  );
};  