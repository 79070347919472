import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Drawer as MuiDrawer, Box as MuiBox, Typography, css } from '@mui/material';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { logoutAction } from '../../store/auth';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { getLayout, setLayout } from '../../store/ui';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Menu } from './Menu';
import { LogApi } from '../../api/log.api';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    overflowY: 'auto',
    height: '100%',
    padding: '25px',
    background: 'linear-gradient(rgb(28, 39, 29) 0%, rgb(0, 0, 0) 79.98%) 0% 0% / cover',
    marginTop: '64px',
    marginLeft: '0px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
    scrollbarColor: '#333 #111',
    '&::-webkit-scrollbar': {
      background: '#111',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#333',
    }
  }),
);

const Drawer = styled(MuiDrawer)`
  background-color: #000;
  & .MuiDrawer-paper {
    background-color: #000;
    scrollbar-color: #333 #111;
    &::-webkit-scrollbar {
      background: #111;
    }
    &::-webkit-scrollbar-thumb {
      background: #333;
    }
  }
  ${({ open }) => open ? css`
    width: ${drawerWidth}px;
    flex-shrink: 0;
    & .MuiDrawer-paper {
      width: ${drawerWidth}px;
      box-sizing: border-box;
    }
  ` : null}
`;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#000',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  ...theme.mixins.toolbar,
  backgroundColor: '#000',
  justifyContent: 'flex-start',
}));

const Box = styled(MuiBox)`
  display: flex;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleTypography = styled(Typography)`
  color: #fff;
`;

const Logo = styled.img`
  width: 80px;
  height: 20px;
  cursor: pointer;
`;

export const Layout = ({
  title,
  children
}) => {
  const { push: navigate, goBack: back } = useHistory();
  const dispatch = useDispatch();
  const { open } = useSelector(getLayout);

  const menuItemClick = useCallback((path) => {
    navigate(path)
  }, [navigate]);

  const logoutClick = useCallback(() => {
    localStorage.removeItem('method');
    localStorage.removeItem('token');
    localStorage.removeItem('token:web');
    dispatch(logoutAction());
    LogApi.create({
      message: 'sign out'
    });
  }, [dispatch]);

  const drawerOpen = useCallback(() => {
    dispatch(setLayout({ open: true }));
  }, [dispatch]);

  const drawerClose = useCallback(() => {
    dispatch(setLayout({ open: false }));
  }, [dispatch]);

  const backClick = useCallback(() => {
    back();
  }, [back]);

  return (
    <Container>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {!open && (
            <MenuIcon onClick={drawerOpen} />
          )}
          <Box flex="1" justifyContent="space-between" alignItems="center">
            <Box marginLeft={open ? '0px' : '10px'}>
              <ArrowBackIosIcon onClick={backClick} />
            </Box>
            <Box marginLeft={open ? '0px' : '10px'}>
              <TitleTypography>
                {title}
              </TitleTypography>
            </Box>
            <Box>
              <Button onClick={logoutClick}>
                LOGOUT
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        variant="persistent"
        anchor="left"
      >
        <DrawerHeader>
          {open && (
            <Logo src="/recrdlogoWhite.svg" onClick={drawerClose} />
          )}
        </DrawerHeader>
        <Divider />
        <Menu onMenuItemClick={menuItemClick} />
      </Drawer>
      <Main open={open}>
        {children}
      </Main>
    </Container>
  )
}