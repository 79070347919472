import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  Box as MuiBox,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { LogApi } from '../../api/log.api';
import { TableCell } from '../../components/Table/TableCell';
import { TableRow } from '../../components/Table/TableRow';
import { uuidToISOStringOfDate } from '../../utilities';
import { useSearch } from '../../components/Search/hook';
import { Search } from '../../components/Search';
import { Text } from '../../components/Text';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Box = styled(MuiBox)`
  display: flex;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

export const ActivityLogs = () => {
  /** @type {[import('../../models/notif-by-user.model').NotifByUserModel[], function]} */
  const [activityLogs, setActivityLogs] = useState([]);
  /** @type {[number, (value: number) => void]} */
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'activityLogs',
    item: searchItems[0],
    text: '',
  });

  const {
    page,
    limit,
    getPageState,
    setPageState,
    next,
    previous,
  } = usePagination({
    key: 'activityLogs'
  });

  useEffect(() => {
    const handler = async () => {
      try {
        setLoader(true);
        const response = await LogApi.getActivityLogs({
          userId: getSearchTextByItemValue('user_id'),
          userName: getSearchTextByItemValue('user_name'),
          includes: ['user'],
          pageState: getPageState(searchTextAsDebounced?.length > 0),
          limit,
        });
        setPageState(response.pageState);
        setActivityLogs(response.models);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };
    handler();
  }, [
    page, 
    limit, 
    searchTextAsDebounced, 
    setPageState, 
    getPageState, 
    getSearchTextByItemValue,
  ]);

  const paginationNextClick = useCallback(() => {
    next();
  }, [next]);

  const paginationPreviousClick = useCallback(() => {
    previous()
  }, [previous]);

  return (
    <Layout>
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <Box width="100%">
          {loader && (
            <Loader>
              <CircularProgress variant='indeterminate' color="inherit" />
            </Loader>
          )}
          {!loader && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Text>Timestamp</Text>
                  </TableCell>
                  <TableCell>
                    <Text>User ID</Text>
                  </TableCell>
                  <TableCell>
                    <Text>User Name</Text>
                  </TableCell>
                  <TableCell>
                    <Text>Details</Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityLogs?.map((v, index) => {
                  const details = v.details ? Object.keys(v.details).map(k => ({ key: k, value: v.details[k] })) : [];
                  return (
                    <TableRow key={v.notif_id + v.notif_user_id + index}>
                      <TableCell>
                        <Text>{uuidToISOStringOfDate(v.notif_id)}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{v.user?.user_id}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{v.user?.user_name}</Text>
                      </TableCell>
                      <TableCell>
                        {details.map(v => (
                          <Box key={v.key}>
                            <Text>
                              {v.key} : {v.value}
                            </Text>
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Box>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
      </Container>
    </Layout>
  );
};  