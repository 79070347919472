import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableHead, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TableRow } from '../../../components/Table/TableRow';
import { TableCell } from '../../../components/Table/TableCell';
import { EditorialApi } from '../../../api/editorial.api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Column } from '../../../components/Column';
import { Row } from '../../../components/Row';
import { Box } from '../../../components/Box';
import CloseIcon from '@mui/icons-material/Close'

/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.zone
 * @param {() => void} props.onClose 
 * @returns 
 */
export const Prompts = ({
  open,
  zone,
  onClose,
}) => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      prompt: '',
    },
    validationSchema: yup.object().shape({
      prompt: yup.string()
        .required(),
    })
  });

  const loadPrompts = useCallback(async () => {
    if (zone === null) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await EditorialApi.getEditorialPrompts({
        zone,
      });
      setPrompts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [zone])

  useEffect(() => {
    loadPrompts();
  }, [loadPrompts]);

  const createPromptClick = useCallback(async () => {
    try {
      const { prompt: newPrompt } = values;
      await EditorialApi.createEditorialPrompt({
        zone,
        prompts: [...prompts, newPrompt],
      });
      loadPrompts();
    } catch (error) {
      console.error(error);
    }
  }, [prompts, values, zone, loadPrompts]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Row justifyContent="space-between">
          <Box>
            Prompts: {zone}
          </Box>
          <Box>
            <CloseIcon onClick={onClose} />
          </Box>
        </Row>
      </DialogTitle>
      <DialogContent>
        {!loading && (
          <Column width="100%" gap="10px">
            <Row gap="10px" alignItems="center">
              <Box flex="1">
                <TextField 
                  fullWidth
                  error={touched.prompt && errors.prompt}
                  helperText={touched.prompt && errors.prompt}
                  label="Prompt"
                  name="prompt"
                  value={values.prompt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box position="relative" top={touched.prompt && errors.prompt ? '-15px' : '0px'}>
                <Button
                  variant="contained"
                  color="success"
                  disabled={!dirty || !isValid}
                  onClick={createPromptClick}
                >
                  Create
                </Button>
              </Box>
            </Row>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Prompt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prompts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="2">
                      No prompts
                    </TableCell>
                  </TableRow>
                )}
                {prompts.map((prompt, index) => (
                  <TableRow key={`${prompt}_${index}`}>
                    <TableCell>
                      {prompt}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Column>
        )}
      </DialogContent>
    </Dialog>
  )
}