import { urlSearchParamSetter } from "../utilities";
import { http } from "./http";

export class UserSettingApi {
  static async getUserSettings(data = {}) {
    const {
      userId,
      attributes,
      pageState,
      limit,
      includes,
    } = data;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('userId', userId);
    setter('attributes', attributes);
    setter('includes', includes);
    setter('pageState', pageState);
    setter('limit', limit);
    const response = await http.get(`/users/settings?${params}`);
    return response.data;
  }

  static async update(userId, data) {
    const {
      saleAllowed,
      saleTrusted,
      saleStarted,
      saleWalletAddresses,
    } = data;
    await http.put(`/users/settings/${userId}`, {
      saleAllowed,
      saleTrusted,
      saleStarted,
      saleWalletAddresses,
    });
  }
}