import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import uuidTime from 'uuid-time'
import { format } from 'date-fns';
import { Row } from '../Row';
import { getChipColorOfPostStatus, getPostStatusLabel, getSaleStatus } from '../../utilities';
import { Box } from '../Box';
import { Text } from '../Text';
import { useUtility } from '../../utilities/hooks';
import { TextColors } from '../../constants';

const StyledDeleteIcon = styled(DeleteIcon)`
  fill: #c62828;
  cursor: pointer;
`;

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin: 0 0 25px 0;
  cursor: pointer;
  gap: 5px;
`;

const Image = styled.img`
  display: flex;
  width: 245px;
  height: 138px;
  border-radius: 6px;
  border: 1px solid #fff;
`;

const transparentImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

/**
 * @param {object} props
 * @param {import('../../models/post.model').PostModel} props.post
 * @param {boolean} props.reboundImage
 * @param {boolean} props.firstFrame
 * @param {boolean} props.isDeleteButton
 * @param {() => void} props.onClick
 * @param {() => void} props.onDelete
 * @returns {import('react').ReactNode}
 */
export const PostCard = ({
  post,
  firstFrame,
  reboundImage,
  isDeleteButton,
  onClick,
  onDelete,
}) => {
  const [thumbnailImageError, setThumbnailImageError] = useState(false);
  const [firstframeImageError, setFirstframeImageError] = useState(false);
  const [reboundImageError, setReboundImageError] = useState(false);

  const saleStatus = useMemo(() => {
    const { sale_status: saleStatus } = post;
    return getSaleStatus(saleStatus)
  }, [post])

  const createdOn = useMemo(() => {
    if (!post || !post.post_id) {
      return <>N/A</>;
    }
    const time = uuidTime.v1(post.post_id);
    const date = new Date(time);
    if (isNaN(date.getTime())) {
      return <>N/A</>
    }
    return <>{format(date, 'dd MMM yyyy - HH:mm:ss')}</>;
  }, [post]);

  const videoLength = useMemo(() => {
    if (!post || !post.video_length_ms) {
      return 'N/A';
    }
    const date = new Date(post.video_length_ms);
    return format(date, 'HH:mm:ss');
  }, [post]);

  const infoClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const deleteClick = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const imageSetter = useCallback((setter, value) => () => {
    setter(value);
  }, []);

  const {
    copyClick,
  } = useUtility();

  return (
    <Container>
      <Box>
        <Box padding="0 10px 0 0" onClick={infoClick}>
          <Image 
            src={thumbnailImageError ? transparentImageBase64 : post.thumb_url} 
            alt={post.title}
            onError={imageSetter(setThumbnailImageError, true)}
            onLoadedData={imageSetter(setThumbnailImageError, false)} 
          />
        </Box>
        {firstFrame && (
          <Box padding="0 10px 0 0" onClick={infoClick}>
            <Image 
              src={firstframeImageError ? transparentImageBase64 : post.first_frame} 
              alt={post.title}
              onError={imageSetter(setFirstframeImageError, true)} 
              onLoadedData={imageSetter(setFirstframeImageError, false)} 
            />
          </Box>
        )}
        {reboundImage && (
          <Box padding="0 10px 0 0" onClick={infoClick}>
            <Image 
              src={reboundImageError ? transparentImageBase64 : post.rebound_image} 
              alt={post.title}
              onError={imageSetter(setReboundImageError, true)} 
              onLoadedData={imageSetter(setReboundImageError, false)} 
            />
          </Box>
        )}
      </Box>
      <Box flexDirection="column" width="300px" padding="0 0 0 20px" onClick={infoClick}>
        <Box>
          <Text color={TextColors.Title}>
            {post.title}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{post.user?.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Subtext}>
            {post.narrative ?? <>&lt;No Description&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" width="200px">
        <Box gap="5px">
          <Text>
            Post type:
          </Text>
          <Chip label={post.post_type} color="primary" size="small" />
        </Box>
        <Box gap="5px">
          <Text>
            Post status:
          </Text>
          <Text>
            <Chip 
              label={getPostStatusLabel(post?.post_status ?? '')} 
              color={getChipColorOfPostStatus(post?.post_status ?? '')}
              size="small" 
            />
          </Text>
        </Box>
        {saleStatus && (
           <Row gap="5px">
            <Box>
              <Text>
                Sale Status:
              </Text>
            </Box>
            <Box>
              <Text color={TextColors.Choice}>
                {saleStatus}
              </Text>
            </Box>
          </Row>
        )}
      </Box>
      <Box flexDirection="column" flex="1">
        <Box  gap="5px" whiteSpace="nowrap">
          <Text color={TextColors.ID}>
            {post.post_id}
          </Text>
          <Box>
            <StyledCopyIcon onClick={copyClick(post?.post_id, 'The post ID has been copied')} />
          </Box>
        </Box>
        <Box gap="5px">
          <Text>
            Created On:
          </Text>
          <Text color={TextColors.DateTime}>
            {createdOn}
          </Text>
        </Box>
        <Box gap="5px">
          <Text>
            Hashtags:
          </Text>
          <Text color={TextColors.List}>
            {post?.hashtags?.join(', ')}
          </Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Info}>{post.height}x{post.width}</Text>
          <Text>|</Text>
          <Text color={TextColors.Info}>{videoLength}</Text>
        </Box>
      </Box>
      <Box flex="0" padding="0 0 0 20px">
        {isDeleteButton && (
          <StyledDeleteIcon onClick={deleteClick} />
        )}
      </Box>
    </Container>
  );
};