import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './routes/Login';
import { Post } from './routes/Post';
import { User } from './routes/User';
import { Users } from './routes/Users';
import { Posts } from './routes/Posts';
import { AuthRoute } from './components/AuthRoute';
import { Privileges } from './routes/Privileges';
import { Features } from './routes/Features';
import { ActivityLogs } from './routes/ActivityLogs';
import { Snackbar } from './components/Snackbar';
import { Dialog } from './components/Dialog';
import { ConfirmDialog } from './components/ConfirmDialog';
import { NoPage } from './components/NoPage';
import { Feedbacks } from './routes/Feedbacks';
import { Feedback } from './routes/Feedback';
import { ReportedUsers } from './routes/ReportedUsers';
import { ReportedPosts } from './routes/ReportedPosts';
import { Celebrities } from './routes/Celebrities';
import { Partners } from './routes/Partners';
import { Partner } from './routes/Partner';
import { EditorialZone } from './routes/EditorialZone';
import { EditorialZonePosts } from './routes/EditorialZonePosts';
import { EditorialPlacements } from './routes/EditorialPlacement';
import { ReportedUser } from './routes/ReportedUser';
import { PrivilegesExternal } from './routes/PrivilegesExternal';

export const App = () => {
  const closeApp = useCallback(() => {
    localStorage.removeItem('search');
    localStorage.removeItem('pagination');
  }, []);

  useEffect(() => {
    window.addEventListener('unload', closeApp);
    return () => window.addEventListener('unload', closeApp);
  }, [closeApp]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <AuthRoute
            path="/editorial/:placement/:zone"
            component={EditorialZonePosts}
            features={['read-menu-editorial']}
          />
          <AuthRoute
            path="/editorial/:placement"
            component={EditorialZone}
            features={['read-menu-editorial']}
          />
          <AuthRoute
            path="/editorial"
            component={EditorialPlacements}
            features={['read-menu-editorial']}
          />
          <AuthRoute
            path="/partners/:code"
            component={Partner}
            features={['menu-partners']}
          />
          <AuthRoute
            path="/partners"
            component={Partners}
            features={['menu-partners']}
          />
          <AuthRoute 
            path="/tv"
            component={NoPage}
            features={['read-menu-tv-producer']}
          />
          <AuthRoute
            path="/celebrities"
            component={Celebrities}
            features={['read-menu-celebrities']}
          />
          <AuthRoute
            path="/campaigns"
            component={NoPage}
            features={['read-menu-campaigns']}
          />
          <AuthRoute
            path="/reports/posts"
            component={ReportedPosts}
            features={['read-menu-reported-posts']}
          />
          <AuthRoute
            path="/reports/users/:reportId"
            component={ReportedUser}
            features={['read-menu-reported-users']}
          />
          <AuthRoute
            path="/reports/users"
            component={ReportedUsers}
            features={['read-menu-reported-users']}
          />
          <AuthRoute
            path="/feedback/:feedbackId"
            component={Feedback}
            features={['read-menu-feedback']}
          />
          <AuthRoute
            path="/feedback"
            component={Feedbacks}
            features={['read-menu-feedback']}
          />
          <AuthRoute 
            path="/activity-logs" 
            component={ActivityLogs} 
            features={['read-menu-activity-logs']}
          />
          <AuthRoute 
            path="/privileges/external" 
            component={PrivilegesExternal}
            features={['read-menu-privileges-external']}
          />
          <AuthRoute 
            path="/privileges" 
            component={Privileges}
            features={['read-menu-privileges']}
          />
          <AuthRoute 
            path="/features" 
            component={Features} 
            features={['read-menu-feature-access']}
          />
          <AuthRoute path="/posts/:postId" component={Post} />
          <AuthRoute path="/users/:userId" component={User} />
          <AuthRoute path="/users" component={Users} />
          <AuthRoute path="/posts" component={Posts} />
          <Redirect path="*" to="/users" />
        </Switch>
      </BrowserRouter>
      <Snackbar />
      <Dialog />
      <ConfirmDialog />
    </>
  );
};