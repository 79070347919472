import React, { useCallback, useMemo } from "react";
import { Row } from "../../../components/Row";
import { Column } from "../../../components/Column";
import { Avatar } from "../../../components/Avatar";
import { Text } from "../../../components/Text";
import { TextColors } from "../../../constants";
import { uuidToISOStringOfDate } from "../../../utilities";

/** @typedef {import('../../../models/user-settings.model').UserSettingsModel} UserSettingModel */

/**
 * @typedef {object} CelebrityCardProps
 * @property {UserSettingModel} userSetting
 * @property {(v: UserSettingModel) => void} onClick 
 */

/**
 * 
 * @param {CelebrityCardProps} props
 * @returns {JSX.Element} 
 */
export const CelebrityCard = ({
  userSetting,
  onClick,
}) => {
  const user = userSetting?.user;

  const images = useMemo(() => {
    return 0;
  }, []);

  const hashtags = useMemo(() => {
    return 0;
  }, []);

  const updated = useMemo(() => {
    if (!user) {
      return 'N/A';
    }
    const datetime = uuidToISOStringOfDate(user.user_id);
    return datetime;
  }, [user]);

  const avatarClick = useCallback(() => {
    onClick(userSetting);
  }, [userSetting, onClick]);

  return (
    <Row>
      <Column>
        <Avatar
          image={userSetting.image_url}
          userName={user?.user_name}
          onPress={avatarClick}
        />
      </Column>
      <Column padding="0 0 0 10px" width="400px">
        <Row>
          <Text color={TextColors.Name}>
            {userSetting.name ?? 'N/A'}
          </Text>
        </Row>
        <Row>
          {userSetting.language ?? 'N/A'}
        </Row>
        <Column>
          <Row>
            <Text>User ID:</Text>
          </Row>
          <Row>
            <Text color={TextColors.ID}>
              {userSetting.user_id}
            </Text>
          </Row>
        </Column>
      </Column>
      <Column padding="0 0 0 10px">
        <Column>
          <Row>
            Updated:
          </Row>
          <Row>
            <Text>{updated}</Text>
          </Row>
        </Column>
        <Column>
          <Row>
            Images:
          </Row>
          <Row>
            <Text>{images}</Text>
          </Row>
        </Column>
        <Column>
          <Row>
            Hashtags:
          </Row>
          <Row>
            <Text>{hashtags}</Text>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}