import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Row } from '../../components/Row';
import { Text } from '../../components/Text';
import { Layout } from '../../components/Layout';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Table, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { SnackbarTypes } from '../../constants';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { TableBody } from '../../components/Table/TableBody';
import { useFormik } from 'formik';
import { Box } from '../../components/Box';
import {
  ContentCopy,
  OpenInNew,
} from '@mui/icons-material';
import { useUtility } from '../../utilities/hooks';
import { ReportApi } from '../../api/report.api';
import { ReportStatuses } from '../../constants/report-statuses';
import { getReportReason } from '../../utilities';

/** @typedef {import('../../models/reported-user.model').ReportedUserModel} ReportedUserModel */

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

const TABS = {
  Detail: 'detail',
  Action: 'action',
}

export const ReportedUser = () => {
  const dispatch = useDispatch();
  const { navigateClick, copyClick } = useUtility();
  const { reportId } = useParams();
  const [tab, setTab] = useState(TABS.Detail);
  /** @type {[ReportedUserModel, (v: ReportedUserModel) => void]} */
  const [report, setReport] = useState(null);
  const [loader, setLoader] = useState(true);

  const form = useFormik({
    initialValues: {
      status: '',
    },
  });

  const {
    values,
    setValues,
    handleChange,
  } = form;

  const loadReport = useCallback(async (loader = true) => {
    try {
      if (loader) setLoader(true);
      const { data: { reports: newReports} } = await ReportApi.getUsers({ 
        reportId,
      });
      const [newReport] = newReports;
      console.log(newReport);
      setReport(newReport);
    } catch (error) {
      console.error(error);
    } finally {
      if (loader) setLoader(false);
    }
  }, [reportId]);

  useEffect(() => {
    if (!report) {
      return;
    }
    setValues({
      status: report.status,
    })
  }, [report, setValues]);

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  const tabsChange = useCallback((_, newTab) => {
    setTab(newTab);
  }, []);

  const actionSaveClick = useCallback(async () => {
    try {
      const {
        status,
      } = values;
      await ReportApi.updateUser(report.reportId, {
        status: status === undefined || status === ''
          ? undefined : status,
      })
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'The reported user has been updated',
      }));
      await loadReport(false);
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: The reported user hasn\'t been updated',
      }));
    }
  }, [report, values, loadReport, dispatch]);

  return (
    <Layout title="Reported User Detail">
      <Container>
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
        <Row>
          <Text>Reported User</Text>
        </Row>
        {!loader && report && (
          <Container>
            <Container>
              <TabContext value={tab}>
                <Tabs value={tab} onChange={tabsChange}>
                  <Tab label="DETAIL" value={TABS.Detail} />
                  <Tab label="ACTION" value={TABS.Action} />
                </Tabs>
                <TabPanel value={TABS.Detail}>
                  <Table>
                    <TableBody backgroundColor="transparent">
                      <TableRow>
                        <TableCell>
                          <Text>Description:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{report.description ?? '<No description>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>Reason:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{getReportReason(report.reason) ?? '<No reason>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>Status:</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{report.status ?? '<No status>'}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User Name of Reporter</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {report.reporterId.user_name}
                            </Text>
                            <OpenInNew onClick={navigateClick(`/users/${report.reporterId.user_id}`)} />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User ID of Reporter</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {report.reporterId.user_id}
                            </Text>
                            <ContentCopy onClick={copyClick(report.reporterId.user_id, 'the user ID of reporter has been copied')} />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User Name</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {report.userId.user_name}
                            </Text>
                            <OpenInNew onClick={navigateClick(`/users/${report.userId.user_id}`)} />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text>User ID</Text>
                        </TableCell>
                        <TableCell>
                          <Box gap="10px">
                            <Text>
                              {report.userId.user_id}
                            </Text>
                            <ContentCopy onClick={copyClick(report.userId.user_id, 'the user ID has been copied')} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabPanel>
                <TabPanel value={TABS.Action}>
                  <Table>
                    <TableBody backgroundColor="transparent">
                      <TableRow>
                        <Container gap="10px">
                          <Row>
                            <Text>Status</Text>
                          </Row>
                          <Row>
                            <FormControl fullWidth>
                              <InputLabel id="select-label-status">Status</InputLabel>
                              <Select
                                labelId="select-label-status"
                                label="Status"
                                name="status"
                                value={values.status}
                                onChange={handleChange}
                              >
                                {Object.keys(ReportStatuses).map(k => (
                                  <MenuItem key={k} value={ReportStatuses[k]}>{k}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Container>
                      </TableRow>
                      <TableRow>
                        <Row justifyContent="flex-end" padding="10px 0 10px 0">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={actionSaveClick}
                          >
                            Save
                          </Button>
                        </Row>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabPanel>
              </TabContext>
            </Container>
          </Container>
        )}
      </Container>
    </Layout>
  )
};