import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import { 
  CircularProgress, 
} from '@mui/material';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { UserSettingApi } from '../../api/user-setting.api';
import { CelebrityCard } from './CelebrityCard';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

/** @typedef {import('../../models/user-settings.model').UserSettingsModel} UserSettingModel */
/** @typedef {import('../../models/user.model').UserModel} UserModel */

export const Celebrities = () => {
  const dispatch = useDispatch();
  const { push: navigate } = useHistory();
  /** @type {[UserSettingModel[], (v: UserSettingModel[]) => void]} */
  const [userSettings, setUserSettings] = useState([]);
  const pageState = useRef({
    action: 'next',
    states: [],
  });
  /** @type {[number, (value: number) => void]} */
  const [page, setPage] = useState(1);
  /** @type {[number, (value: number) => void]} */
  const [limit] = useState(10);
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'search:celebrities',
    item: searchItems[0],
    text: '',
  });

  const loadUserSettings = useCallback(async () => {
    try {
      setLoader(true);
      const data = await UserSettingApi.getUserSettings({
        pageState: pageState.current.states[
          pageState.current.action === 'next'
            ? pageState.current.states.length - 1
            : pageState.current.states.length - 3
        ],
        limit,
        includes: ['user'],
        attributes: ['Celeb'],
        userId: getSearchTextByItemValue('user_id'),
        userName: getSearchTextByItemValue('user_name'),
      });
      if (pageState.current.action === 'next') {
        pageState.current.states.push(data.pageState);
      }
      if (pageState.current.action === 'previous') {
        pageState.current.states.pop();
      }
      setUserSettings(data.userSettings);
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The users can't be loaded",
      }))
    } finally {
      setLoader(false);
    }
  }, [limit, getSearchTextByItemValue, dispatch]);

  useEffect(() => {
    loadUserSettings();
  }, [loadUserSettings]);


  const paginationNextClick = useCallback(() => {
    pageState.current.action = 'next';
    setPage(page => page + 1);
    loadUserSettings();
  }, [loadUserSettings]);

  const paginationPreviousClick = useCallback(() => {
    pageState.current.action = 'previous';
    setPage(page => page <= 1 ? 1 : page - 1);
    loadUserSettings();
  }, [loadUserSettings]);

  const celebrityCardClick = useCallback(
    /** @param {UserSettingModel} userSetting */
    (userSetting) => {
      navigate(`/users/${userSetting.user_id}`);
    }, [navigate]
  );

  return (
    <Layout title="Celebrities">
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <ListPanel>
          {userSettings.map(userSetting => (
            <CelebrityCard
              key={userSetting.user_id}
              userSetting={userSetting}
              onClick={celebrityCardClick}
            />
          ))}
        </ListPanel>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
      </Container>
    </Layout>
  );
};  