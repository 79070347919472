import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableHead, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TableRow } from '../../../components/Table/TableRow';
import { TableCell } from '../../../components/Table/TableCell';
import { EditorialApi } from '../../../api/editorial.api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Column } from '../../../components/Column';
import { Row } from '../../../components/Row';
import { Box } from '../../../components/Box';
import CloseIcon from '@mui/icons-material/Close';

/** @typedef {import('../../../models/editorial-text.model').EditorialTextModel} EditorialTextModel */

/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.zone
 * @param {string} props.placement
 * @param {() => void} props.onClose 
 * @returns 
 */
export const Texts = ({
  open,
  zone,
  placement,
  onClose,
}) => {
  /** @type {[EditorialTextModel[], (v: EditorialTextModel[]) => void]} */
  const [texts, setTexts] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string()
        .required(),
      body: yup.string()
        .required(),
    })
  });

  const loadTexts = useCallback(async () => {
    if (zone === null || placement === null) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await EditorialApi.getEditorialTexts({
        zone,
        placement,
      });
      setTexts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [zone, placement])

  useEffect(() => {
    loadTexts();
  }, [loadTexts]);

  const createTextClick = useCallback(async () => {
    try {
      const { title, body  } = values;
      await EditorialApi.createEditorialText({
        zone,
        placement,
        title,
        body,
      });
      loadTexts();
    } catch (error) {
      console.error(error);
    }
  }, [values, zone, placement, loadTexts]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Row justifyContent="space-between">
          <Box>
            Texts: {placement} - {zone}
          </Box>
          <Box>
            <CloseIcon onClick={onClose} />
          </Box>
        </Row>
      </DialogTitle>
      <DialogContent>
        {!loading && (
          <Column width="100%" gap="10px">
            <Row gap="10px" alignItems="center">
              <Column width="100%" flex="1" gap="10px">
                <Row>
                  <TextField 
                    fullWidth
                    error={touched.title && errors.title}
                    helperText={touched.title && errors.title}
                    label="New title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <TextField 
                    fullWidth
                    error={touched.body && errors.body}
                    helperText={touched.body && errors.body}
                    label="New body"
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
              </Column>
              <Box position="relative">
                <Button
                  variant="contained"
                  color="success"
                  disabled={!dirty || !isValid}
                  onClick={createTextClick}
                >
                  Create
                </Button>
              </Box>
            </Row>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Title
                  </TableCell>
                  <TableCell>
                    Body
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {texts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="2">
                      No texts
                    </TableCell>
                  </TableRow>
                )}
                {texts.map((text, index) => (
                  <TableRow key={`${text.title}_${index}`}>
                    <TableCell>
                      {text.title}
                    </TableCell>
                    <TableCell>
                      {text.body}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Column>
        )}
      </DialogContent>
    </Dialog>
  )
}