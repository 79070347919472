import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  CircularProgress, 
} from '@mui/material';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { ReportedPostCard } from './ReportedPostCard';
import { ReportApi } from '../../api/report.api';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'Reported Post ID',
    value: 'reported_post_id',
  },
  {
    label: 'Reporter User ID',
    value: 'reporter_id',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

/** @typedef {import('../../models/reported-post.model').ReportedPostModel} ReportedPostModel */

export const ReportedPosts = () => {
  const dispatch = useDispatch();
  /** @type {[ReportedPostModel[], (value: ReportedPostModel[]) => void]} */
  const [reportedPosts, setReportedPosts] = useState([]);
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'reportedPosts',
    item: searchItems[0],
    text: '',
  });

  const {
    page,
    limit,
    setPageState,
    getPageState,
    next,
    previous,
  } = usePagination({
    key: 'reportedPosts'
  });

  const loadReportedPosts = useCallback(async () => {
    try {
      setLoader(true);
      const data = await ReportApi.getPosts({
        pageState: getPageState(searchTextAsDebounced?.length > 0),
        limit,
        reportedPostId: getSearchTextByItemValue('reported_post_id', true),
        reporterId: getSearchTextByItemValue('reporter_id', true),
      });
      setPageState(data.pageState);
      setReportedPosts(data.reportedPosts);
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The reported posts can't be loaded",
      }))
    } finally {
      setLoader(false);
    }
  }, [
    limit, 
    searchTextAsDebounced,
    getSearchTextByItemValue, 
    getPageState,
    setPageState,
    dispatch,
  ]);

  useEffect(() => {
    loadReportedPosts();
  }, [loadReportedPosts]);

  const paginationNextClick = useCallback(() => {
    next();
  }, [next]);

  const paginationPreviousClick = useCallback(() => {
    previous();
  }, [previous]);

  return (
    <Layout title="Reported Posts">
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <ListPanel>
          {reportedPosts.map(reportedPost => (
            <ReportedPostCard
              key={reportedPost.reported_post_id}
              reportedPost={reportedPost}
            />
          ))}
        </ListPanel>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
      </Container>
    </Layout>
  );
};  