import React, { useMemo, useCallback } from 'react';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { useFormikContext } from 'formik';
import { uuidToISOStringOfDate } from '../../../../utilities';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { hasFeatures } from '../../../../store/auth';

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
`;

const Container = styled(Box)`
  flex-direction: column;
  width: 100%;
`;

const Row = styled(Box)`
  width: 100%;
  align-items: center;
`;

const CellLabel = styled(Box)`
  min-width: ${({ variant }) => {
    if (variant === 'secondary') {
      return '410px';
    }
    return '240px';
  }};
`;

const CellInput = styled(Box)`
  flex: 1;
  margin: 0 0 0 10px;
`;

const CellAction = styled(Box)`
  margin: 0 0 0 10px;
`;

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../../models/user.model').UserModel} UserModel */

/**
 * 
 * @param {object} props 
 * @param {UserModel} props.user
 * @param {(field: string) => void} props.onSave
 * @returns {ReactNode}
 */
export const About = ({
  user,
  onSave,
}) => {
  const form = useFormikContext();
  const isPassword = useSelector(hasFeatures(['reset-password-allowed']));
  const isEditUser = useSelector(hasFeatures(['edit-user-info']));

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
  } = form;

  const createdOn = useMemo(() => {
    return uuidToISOStringOfDate(user?.user_id) ?? <>N/A</>;
  }, [user]);

  const lastLoginOn = useMemo(() => {
    return uuidToISOStringOfDate(user?.last_login_tms) ?? <>N/A</>;
  }, [user]);

  const editClick = useCallback((field) => () => {
    setValues({
      ...values,
      [field]: false,
    })
  }, [values, setValues]);

  const saveClick = useCallback((field) => () => {
    onSave(field);
  }, [onSave]);

  return (
    <Container>
      {isEditUser && (
        <>
          <Row alignItems="center">
            <CellLabel>
              <Text>Username:</Text>
            </CellLabel>
            <CellInput>
              <TextField 
                name="userName"
                value={values.userName}
                disabled={values.userName_disable}
                fullWidth
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </CellInput>
            <CellAction>
              {values.userName_disable && (
                <StyledEditIcon onClick={editClick('userName_disable')} />
              )}
              {!values.userName_disable && (
                <CheckCircleIcon onClick={saveClick('userName')}  />
              )}
            </CellAction>
          </Row>
          <Row>
            <CellLabel>
              <Text>Email:</Text>
            </CellLabel>
            <CellInput>
              <TextField 
                name="email"
                value={values.email} 
                disabled 
                fullWidth
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </CellInput>
            <CellAction>
              <StyledEditIcon onClick={editClick} />
            </CellAction>
          </Row>
          <Row>
            <CellLabel>
              <Text>Phone Number:</Text>
            </CellLabel>
            <CellInput>
              <TextField 
                name="phoneNumber"
                value={values.phoneNumber} 
                disabled 
                fullWidth
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </CellInput>
            <CellAction>
              <StyledEditIcon onClick={editClick} />
            </CellAction>
          </Row>
          <Row>
            <CellLabel>
              <Text>Full bio:</Text>
            </CellLabel>
            <CellInput>
              <TextField 
                name="fullBio"
                value={values.fullBio} 
                disabled={values.fullBio_disable}
                fullWidth
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </CellInput>
            <CellAction>
              {values.fullBio_disable && (
                <StyledEditIcon onClick={editClick('fullBio_disable')} />
              )}
              {!values.fullBio_disable && (
                <CheckCircleIcon onClick={saveClick('fullBio')}  />
              )}
            </CellAction>
          </Row>
          <Row>
            <CellLabel>
              <Text>Short bio:</Text>
            </CellLabel>
            <CellInput>
              <TextField 
                name="shortBio"
                value={values.shortBio} 
                disabled={values.shortBio_disable}
                fullWidth
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </CellInput>
            <CellAction>
              {values.shortBio_disable && (
                <StyledEditIcon onClick={editClick('shortBio_disable')} />
              )}
              {!values.shortBio_disable && (
                <CheckCircleIcon onClick={saveClick('shortBio')}  />
              )}
            </CellAction>
          </Row>
        </>
      )}
      {isPassword && (
        <Row>
          <CellLabel>
            <Text>Password:</Text>
          </CellLabel>
          <CellInput>
            <TextField 
              name="password"
              value={values.password} 
              disabled={values.password_disable}
              fullWidth
              onChange={handleChange} 
              onBlur={handleBlur}
            />
          </CellInput>
          <CellAction>
            {values.password_disable && (
              <StyledEditIcon onClick={editClick('password_disable')} />
            )}
            {!values.password_disable && (
              <CheckCircleIcon onClick={saveClick('password')}  />
            )}
          </CellAction>
        </Row>
      )}
      <Row>
        <CellLabel variant="secondary">
          <Text>Date of birth:</Text>
        </CellLabel>
        <CellInput>
          <Text>{user.dob}</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Number of Followers:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Number of Following:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Number of Subscribers:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Number of Blocked Users:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Number of times user has been blocked:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>(Future) Number of times user has been reported:</Text>
        </CellLabel>
        <CellInput>
          <Text>0</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Created On:</Text>
        </CellLabel>
        <CellInput>
          <Text>{createdOn}</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Last Login:</Text>
        </CellLabel>
        <CellInput>
          <Text>{lastLoginOn}</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>Last Login:</Text>
        </CellLabel>
        <CellInput>
          <Text>{user.phone_verified ? <>Verified</> : <>Not Verified</>}</Text>
        </CellInput>
      </Row>
      <Row>
        <CellLabel variant="secondary">
          <Text>(Future) Type of login:</Text>
        </CellLabel>
        <CellInput>
          <Text>&lt;Google, Apple, Email, Phone&gt;</Text>
        </CellInput>
      </Row>
      <Row alignItems="flex-start">
        <CellLabel variant="secondary">
          <Text>Social Links:</Text>
        </CellLabel>
        <CellInput flexDirection="column">
          {user?.userSettings?.social_accounts_v4?.map(v => (
            <Box key={v.title}>
              <Text>{v.social_id}:</Text>
              <Text>{v.title}</Text>
              <Text>{v.endpoint_url}</Text>
            </Box>
          ))}
        </CellInput>
      </Row>
    </Container>
  )
}