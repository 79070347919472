import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import { 
  CircularProgress, 
} from '@mui/material';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { ReportedUserCard } from './ReportedUserCard';
import { ReportApi } from '../../api/report.api';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'Reporter ID',
    value: 'reporter_id',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

/** @typedef {import('../../models/reported-user.model').ReportedUserModel} ReportedUserModel */

export const ReportedUsers = () => {
  const dispatch = useDispatch();
  /** @type {History} */
  const { push: navigate } = useHistory();
  /** @type {[ReportedUserModel[], (value: ReportedUserModel[]) => void]} */
  const [reports, setReports] = useState([]);
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'reportedUsers',
    item: searchItems[0],
    text: '',
  });

  const {
    page,
    limit,
    next,
    previous,
  } = usePagination();

  const loadReports = useCallback(async () => {
    try {
      setLoader(true);
      let offset = (page - 1) * limit;
      const { data: { reports: newReports }} = await ReportApi.getUsers({
        reporterId: getSearchTextByItemValue('reporter_id', true),
        userId: getSearchTextByItemValue('user_id', true),
        limit,
        offset,
        searchTextAsDebounced,
      });
      setReports(newReports);
    } catch (error) {
      console.error(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The reported users can't be loaded",
      }))
    } finally {
      setLoader(false);
    }
  }, [
    page,
    limit, 
    searchTextAsDebounced,
    getSearchTextByItemValue,
    dispatch,
  ]);

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  const reportedUserClick = useCallback((/** @type {ReportedUserModel} */ report) => () => {
    navigate(`/reports/users/${report.reportId}`);
  }, [navigate]);

  const paginationNextClick = useCallback(() => {
    next();
  }, [next]);

  const paginationPreviousClick = useCallback(() => {
    previous();
  }, [previous]);

  return (
    <Layout title="Reported Users">
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <ListPanel>
          {reports.map(report => (
            <ReportedUserCard
              key={report.reportId}
              report={report}
              onClick={reportedUserClick(report)}
            />
          ))}
        </ListPanel>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
      </Container>
    </Layout>
  );
};  