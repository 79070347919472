import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { FormTypes, SnackbarTypes, TextColors } from '../../constants';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import { EditorialApi } from '../../api/editorial.api';
import { useParams } from 'react-router-dom';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { hasFeatures } from '../../store/auth';
import { useUtility } from '../../utilities/hooks';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { Column } from '../../components/Column';
import { Text } from '../../components/Text';
import { Row } from '../../components/Row';
import { Box } from '../../components/Box';
import { Form } from './Form';
import { Prompts } from './Prompts';
import { Texts } from './Texts';

/** @typedef {import('../../models/editorial-zone.model').EditorialZoneModel} EditorialZoneModel */
/** @typedef {import('../../models/editorial-zone.model').EditorialZoneDataModel} EditorialZoneDataModel */

export const EditorialZone = () => {
  const {
    navigateClick
  } = useUtility();
  const dispatch = useDispatch();
  const isCreate = useSelector(hasFeatures(['insert-editorial-zone']));
  const {
    result: deletedResult,
    data: deletedData,
  } = useSelector(getConfirmDialog);
  const { placement } = useParams();
  /** @type {[EditorialZoneDataModel[], (v: EditorialZoneDataModel[]) => void]} */
  const [zoneData, setZoneData] = useState([]);
  const [isoCodes, setIsoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const testForOptionaFields = (fields) => 
    fields.country?.trim()?.length > 0 ||
    fields.region?.trim()?.length > 0 ||
    fields.city?.trim()?.length > 0 ||
    fields.locale?.trim()?.length > 0;

  const form = useFormik({
    initialValues: {
      placement: '',
      zone: '',
      country: '',
      region: '',
      city: '',
      locale: '',
    },
    validationSchema: yup.object().shape({
      placement: yup.string()
        .required(),
      zone: yup.string()
        .test({
          test: (value, _context) => value?.trim()?.length > 0,
          message: 'the zone has to have at least 1 symbol'
        })
        .required(),
      country: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      region: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      city: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      locale: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
    })
  });

  const {
    values,
    isValid,
    setFieldValue,
    setValues,
  } = form;

  const loadEditorialZone = useCallback(async (loader = true) => {
    try {
      if (loader) setLoading(true);
      const zoneData = await EditorialApi.getEditorialZone({
        placement,
      });
      const newIsoCodes = await EditorialApi.getIsoCodes();
      setIsoCodes(newIsoCodes);
      const newZoneData = (zoneData?.data ?? []).map(v => {
        const isoCode = newIsoCodes.find(t => t.iso === v.country);
        if (isoCode) {
          v.countryName = isoCode.name;
        }
        return v;
      })
      setZoneData(newZoneData);
      setValues({
        placement,
        city: '',
        country: '',
        locale: '',
        region: '',
        zone: '',
      })
    } catch (error) {
      console.log(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The editorial zone can't be loaded",
      }))
    } finally {
      if (loader) setLoading(false);
    }
  }, [placement, dispatch, setValues]);

  useEffect(() => {
    loadEditorialZone();
  }, [loadEditorialZone]);

  const deleteZone = useCallback(async () => {
    try {
      setLoading(true);
      /** @type {EditorialZoneDataModel} */
      const zoneData = deletedData;
      await EditorialApi.deleteEditorialZone({
        placement: placement,
        country: zoneData.country,
        city: zoneData.city,
        locale: zoneData.locale,
        region: zoneData.region,
        zone: zoneData.zone,
      })
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The editorial zone has been deleted",
      }));
      loadEditorialZone();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The editorial zone hasn't been deleted",
      }))
    } finally {
      setLoading(false);
    }
  }, [placement, deletedData, loadEditorialZone, dispatch]);

  useEffect(() => {
    if (deletedResult === true) {
      dispatch(setConfirmDialog({
        result: null,
      }));
      deleteZone();
    }
  }, [deletedResult, dispatch, deleteZone]);

  const zoneDataOpenClick = useCallback(
    (zoneData) => navigateClick(`/editorial/${placement}/${zoneData.zone}`), 
    [placement, navigateClick],
  );

  const zoneDataDeleteClick = useCallback((zoneData) => async () => {
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader('zone'),
      content: getConfirmDialogContent('zone'),
      result: null,
      data: zoneData,
    }));
  }, [dispatch]);

  const createZone = useCallback(async () => {
    try {
      if (!isValid) {
        throw Error();
      }
      setLoading(true);
      const {
        placement,
        country,
        city,
        locale,
        region,
        zone,
      } = values;
      await EditorialApi.createEditorialZone({
        placement,
        country,
        city,
        locale,
        region,
        zone,
      });
      setValues({
        placement,
        city: '',
        country: '',
        locale: '',
        region: '',
        zone: '',
      });
      loadEditorialZone(true);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The new zone has been created",
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: The new zone hasn't been created",
      }))
    } finally {
      setLoading(false);
    }
  }, [values, isValid, setValues, loadEditorialZone, dispatch]);

  const createEditorialZoneClick = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const formSubmit = useCallback(() => {
    setIsFormOpen(false);
    createZone();
  }, [createZone]);

  const formCancel = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  const regions = useMemo(() => {
    const result = isoCodes
      .map(v => v.region)
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(v => v !== '');
    return result;
  }, [isoCodes]);

  const region = values.region;

  useEffect(() => {
    setFieldValue('country', '');
  }, [region, setFieldValue])

  const countries = useMemo(() => {
    if (region === '') {
      return isoCodes;
    }
    const result = isoCodes
      .filter(v => v.region === region);
    return result;
  }, [region, isoCodes]);

  const [promptsProps, setPromptsProps] = useState({
    open: false,
    zone: null,
  });

  const [textsProps, setTextsProps] = useState({
    open: false,
    zone: null,
  });

  const promptsClose = useCallback(() => {
    setPromptsProps(v => ({
      ...v,
      open: false,
    }))
  }, []);

  const textsClose = useCallback(() => {
    setTextsProps(v => ({
      ...v,
      open: false,
    }))
  }, []);

  const zoneDataPromptsClick = useCallback(
    /** @param {EditorialZoneDataModel} zoneData */
    (zoneData) => () => {
      setPromptsProps(prev => ({
        ...prev,
        open: true,
        zone: zoneData.zone,
      }))
    }, [],
  );

  const zoneDataTextsClick = useCallback(
    /** @param {EditorialZoneDataModel} zoneData */
    (zoneData) => () => {
      setTextsProps(prev => ({
        ...prev,
        open: true,
        zone: zoneData.zone,
      }));
    }, [],
  )

  return (
    <Layout title="Editorial Placement">
      <Container>
        <Row gap="5px" justifyContent="space-between" padding="0 0 20px 0">
          <Box>
            <Text>Placement:</Text>
            <Text color={TextColors.ID}>{placement}</Text>
          </Box>
          <Box>
            {isCreate && (
              <Button
                variant="contained"
                color="warning"
                onClick={createEditorialZoneClick}
              >
                Create
              </Button>
            )}
          </Box>
        </Row>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Zone
              </TableCell>
              <TableCell>
                Region
              </TableCell>
              <TableCell>
                Country
              </TableCell>
              <TableCell>
                City
              </TableCell>
              <TableCell>
                Location
              </TableCell>
              <TableCell>
                Post Count
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zoneData.map((v, i) => (
              <TableRow key={v.zone + i}>
                <TableCell>
                  {v.zone}
                </TableCell>
                <TableCell>
                  {v.region}
                </TableCell>
                <TableCell>
                  ({v.country}) {v.countryName}
                </TableCell>
                <TableCell>
                  {v.city}
                </TableCell>
                <TableCell>
                  {v.locale}
                </TableCell>
                <TableCell>
                  {v.zonePostCount}
                </TableCell>
                <TableCell>
                  <Column gap="10px" width="100px">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={zoneDataOpenClick(v)}
                    >
                      Posts
                    </Button>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={zoneDataPromptsClick(v)}
                    >
                      Prompts
                    </Button>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={zoneDataTextsClick(v)}
                    >
                      Texts
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={zoneDataDeleteClick(v)}
                    >
                      Delete
                    </Button>
                  </Column>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <FormikProvider value={form}>
          <Form
            formType={FormTypes.Create}
            isOpen={isFormOpen}
            countries={countries}
            regions={regions}
            onSubmit={formSubmit}
            onCancel={formCancel}
          />
        </FormikProvider>
        <Prompts
          open={promptsProps.open}
          zone={promptsProps.zone}
          onClose={promptsClose}
        />
        <Texts
          open={textsProps.open}
          placement={placement}
          zone={textsProps.zone}
          onClose={textsClose}
        />
        <Loader loading={loading} />
      </Container>
    </Layout>
  );
};  